import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "library.list",
    path: `${APP_PREFIX_PATH}/library`,
    component: React.lazy(() =>
      import("views/app-views/pages/Library/Library")
    ),
  },
  {
    key: "library.update",
    path: `${APP_PREFIX_PATH}/library/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/Library/LibraryAdd")
    ),
  },
  {
    key: "library.add",
    path: `${APP_PREFIX_PATH}/library/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/Library/LibraryAdd")
    ),
  },
// Dashboard 
  {
    key: "user.dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/pages/dashboard/DashboardList")
    ),
  },

  // admin=========>
  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/members-management/admin/adminList`,
    component: React.lazy(() =>
      import("views/app-views/pages/admin/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-add`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/admin/AdminAdd")),
  },
  {
    key: "user.admins.profile",
    path: `${APP_PREFIX_PATH}/members-management/admin/admin-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/AdminProfile")
    ),
  },

   // user=========>
   {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/members-management/user/userList`,
    component: React.lazy(() =>
      import("views/app-views/pages/user/UserList")
    ),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/members-management/user/user-add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/members-management/user/user-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/user-profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },

  // media==========>

  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/media/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/media/MediaList")
    ),
  },
  // simplifica ai========>
  {
    key: "media.list",
    path: `${APP_PREFIX_PATH}/simplifica_ai/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/AI/Ai")
    ),
  },

  // CMS Management===> Pages (Sakil)

  {
    key: "cms.pages.list",
    path: `${APP_PREFIX_PATH}/cms/pages`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/SitePages")
    ),
  },
  {
    key: "cms.updatePages.edit",
    path: `${APP_PREFIX_PATH}/cms/pages/update/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/sitePages/editPage/EditPage")
    ),
  },

  // CMS Management===> Email template (Sakil)

  {
    key: "cms.emailTemplate.list",
    path: `${APP_PREFIX_PATH}/cms/emailTemplate`,
    component: React.lazy(() =>
      import("views/app-views/pages/emailTemplate/index")
    ),
  },
  {
    key: "cms.emailTemplate.update",
    path: `${APP_PREFIX_PATH}/cms/emailTemplate/update/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/pages/emailTemplate/UpdateEmailTemplates/UpdateEmailTemplate"
      )
    ),
  },

  // Plans
  {
    key: "plans.list",
    path: `${APP_PREFIX_PATH}/plans`,
    component: React.lazy(() =>
      import("views/app-views/pages/plans/PlansList")
    ),
  },
  {
    key: "user.plans.add",
    path: `${APP_PREFIX_PATH}/members-management/plans/plans-add`,
    component: React.lazy(() => import("views/app-views/pages/plans/PlansAdd")),
  },
  {
    key: "user.plans.edit",
    path: `${APP_PREFIX_PATH}/members-management/plans/plans-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/plans/PlansAdd")),
  },

  // Subscription
  {
    key: "subscription.list",
    path: `${APP_PREFIX_PATH}/subscription`,
    component: React.lazy(() =>
      import("views/app-views/pages/subscriptions/SubscriptionList")
    ),
  }, 

   //CMS Management ===> Folder

   {
    key: "cms.folder.list",
    path: `${APP_PREFIX_PATH}/cms/folder`,
    component: React.lazy(() =>
      import("views/app-views/pages/folder/FolderList")
    ),
  },
  {
    key: "user.folder.add",
    path: `${APP_PREFIX_PATH}/members-management/folder/folder-add`,
    component: React.lazy(() => import("views/app-views/pages/folder/AddFolder")),
  },
  {
    key: "user.folder.edit",
    path: `${APP_PREFIX_PATH}/members-management/folder/folder-edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/folder/AddFolder")),
  },

  // CMS Management===> Settings (Sakil)

  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/cms/settings/*`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  //Change Password ===>(Sakil)
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },

  //Edit Profile ===>(Sakil)
  {
    key: "profile.edit",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },

  //Billing ===>(Sakil)
  {
    key: "profile.billing",
    path: `${APP_PREFIX_PATH}/billing`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/Billing")
    ),
  },

  //Notification ===>(Sakil)
  {
    key: "profile.notificatiion",
    path: `${APP_PREFIX_PATH}/notifications`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/Notification")
    ),
  },
];
