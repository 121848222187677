import { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";
import { verifyToken } from "services/appServices/auth";
import Loading from "components/shared-components/Loading";
import { authDispatch } from "store/slices/authSlice";
import favImg from "../assets/icons/favicon.svg";
import { loadSettingDetails } from "services/appServices/settingDetailService";
import { siteSettingsDispatch } from "store/slices/siteSettingsSlice";

const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState({
    loaded: false,
    verified: false,
  });
  const token = localStorage.getItem("auth_token");
  const location = useLocation();
  const dispatch = useDispatch();
  const favicon = useSelector(
    (state) => state?.siteSettingsSlice?.data?.data?.favicon
  );

  useEffect(() => {
    verifyToken(setAuthenticated).then((data) =>
      dispatch(authDispatch(data.data))
    );
  }, []);

  useEffect(() => {
    loadSettingDetails().then((res) => dispatch(siteSettingsDispatch(res)));
  }, []);

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  if (!favicon || favicon != null) {
    link.href =
      favicon instanceof File ? URL.createObjectURL(favicon) : favicon;
  } else {
    link.href = favImg;
  }

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  if (!authenticated.loaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
