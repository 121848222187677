import { apiRequest } from "utils/async/apiUtils";

export const query = async (params) => {
  const query = {};
  if (params.type === "translate") {
    params?.from && (query["from"] = params.from);
    params?.to && (query["to"] = params.to);
  }
  try {
    const res = await apiRequest("post", "chat/add", {
      body: {
        content: params.query,
        type: params.type,
        ...query,
      },
    });
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const loadFolder = async (params,  setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", "folders", {
      queries: {
        userId: params?.userId ? params?.userId : "",
        folderId: params?.folderId ? params?.folderId : "",
        channelId: params?.channelId ? params?.channelId : "",
        limit: params.limit,
        offset: params.offset,
        keyword: params.keyword,
        sortQuery: params.sortKey || "createdAt",
      },
    });
    const data = res.data;
    setLoaded(true);
    console.log({ data });
    return data;
  } catch (err) {
    setLoaded(true);
    return err.response.data;
  }
};

export const channelDetails = async (params, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", `channel/detail/${params.channelId}`);
    setLoaded(true);
    return res.data;
  } catch (err) {
    setLoaded(true);
    return err.response.data;
  }
};

export const chatAdd = async (params) => {
  try {
    const res = await apiRequest("post", "chats", {
      body: {
        content: params.query,
        type: params.type
      }
    });
    return res.data;
  } catch (error) {
    return error.response;
  }
};
