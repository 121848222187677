import { apiRequest } from "utils/async/apiUtils";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "site-settings");

    const data = res.data;
    setResData(data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err.response
  }
};

export const editSettings = async (data, setIsLoaded = () => {}) => {
  setIsLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "site-settings",
      {
        body: {
          ...data,
        },
      },
      "multipart/form-data"
    );
    setIsLoaded(true);
    return res;
  } catch (error) {
    setIsLoaded(true);
    return error.response;
  }
};
