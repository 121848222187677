import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import siteSettingsSlice from './slices/siteSettingsSlice'
import clickOutSideSlice from './slices/clickOutSideSlice'
import aiSlice from './slices/aiSlice'
import currentSystemTheme from './slices/systemThemeSlice'
import alert from './slices/alertSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        siteSettingsSlice,
        clickOutSideSlice,
        aiSlice,
        currentSystemTheme,
        alert,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
