import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAlert: false,
  message: undefined,
  status: undefined,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.isAlert = true;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    hideAlert: (state, action) => {
      state.isAlert = false;
      state.message = undefined;
      state.status = undefined;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
