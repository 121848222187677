import React from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import { onSwitchTheme } from "store/slices/themeSlice";
import { useEffect } from "react";
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const isDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

  
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (isDarkTheme) {
  //     dispatch(onSwitchTheme("dark"));
  //   }
  // }, [isDarkTheme]);

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Layouts />
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
