import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTheme: "light",
};

const systemThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    getTheme: (state, action) => {
      state.currentTheme = action;
    },
  },
});

export const { getTheme } = systemThemeSlice.actions;
export default systemThemeSlice.reducer;
