import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { query } from "services/appServices/aiService";

const initialState = {
  data: {},
  loaded: true,
  isHitted: false,
  error: undefined,
};

export const addQuery = createAsyncThunk(
  "chatGpt/query",
  async (data, { rejectWithValue }) => {
    try {
      const response = await query(data);
      if (response) {
        return response.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const aiSlice = createSlice({
  name: "AI",
  initialState,
  reducers: {
    queryData: (state, action) => {
      state.data = action.payload;
    },
    isNotHitted: (state, action) => {
      state.isHitted = false;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(addQuery.pending, (state) => {
        state.isHitted = true;
        state.loaded = false;
        state.data = {};
      })
      .addCase(addQuery.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loaded = true;
        state.data = action.payload;
      })
      .addCase(addQuery.rejected, (state, action) => {
        state.loaded = true;
        state.error = action.payload;
        state.data = {};
        state.isHitted = false;
      }),
});

export const { queryData,isNotHitted } = aiSlice.actions;
export default aiSlice.reducer;
